/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const URL = 'API_URL';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public url = 'https://caapi.woutick.es';
  public showHeader = true;
  public params;

  public jwt?;
  private eventId?;

  private codes = [];

  constructor(
    private http: HttpClient
  ) { }

  setEventId(eventId) {
    this.eventId = eventId;
    localStorage.setItem('eventId', eventId);
  }

  getEventId() {
    if (this.eventId) {
      return this.eventId;
    }
    return localStorage.getItem('eventId');
  }

  setJWT(jwt) {
    this.jwt = jwt;
    localStorage.setItem('selecteduserJWT', jwt);
  }

  setInitialURL() {
    const value = localStorage.getItem(URL);
    if (value !== null) {
      this.setURL(value);
    } else {
      this.setURL('https://caapi.woutick.es');
    }
  }

  setURL(url: string) {
    this.url = url;
    localStorage.setItem(URL, url);
  }

  public loginUser(user: any): any {
    const json = `{
      "email": "` + user.username + `",
      "password": "` + user.password + `",
      "event_id": "` + this.getEventId() + `"
    }`;
    return this.http.post(this.url + '/congress/login', json).toPromise()
      .then((result) => result);
  }

  public checkToken(): any {
    let jwt = localStorage.getItem('selecteduserJWT');
    if (jwt === null) {
      jwt = this.jwt;
    }
    return this.http.get(this.url + '/congress/profile', {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + jwt
      })
    }).toPromise()
      .then(() => true, () => false);
  }

  public getAssistants(): any {
    return this.http.get(this.url + '/congress/users/' + this.getEventId(),
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise().then((result) => result);
  }

  public getGames(): any {
    return this.http.get(this.url + '/congress/games', {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise()
      .then((result) => result);
  }

  public checkCode(code): any {
    const json = `{
      "code": "` + code + `"
    }`;
    return this.http.post(this.url + '/congress/getcodegamification',
      json,
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise()
      .then((result) => result);
  }

  public getCodesUserUsed(): any {
    return this.http.get(this.url + '/congress/getcodeused',
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise()
      .then((result) => result);
  }

  public getCodeUserInfo(code): any {
    return this.http.get(this.url + '/congress/getcodeused/' + code,
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise()
      .then((result) => result);
  }

  public getCodeInfo(code): any {
    return this.http.get(this.url + '/congress/getcode/' + code,
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise()
      .then((result) => result);
  }

  public validateCode(code): any {
    const json = `{
      "code": "` + code + `"
    }`;
    return this.http.post(this.url + '/congress/getexchangedcodes',
      json,
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise()
      .then((result) => result);
  }

  public getAllValidatedCodes(): any {
    return this.http.get(this.url + '/congress/getallcodes',
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise()
      .then((result) => result);
  }

}
