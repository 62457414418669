/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { InfoCodeComponent } from 'src/app/shared/pages/popovers/infoCode/infoCode.component';
import { ApiService } from 'src/app/shared/services/api/api.service';

@Component({
  selector: 'code-card',
  templateUrl: './codeCard.component.html',
  styleUrls: ['./codeCard.component.scss'],
})
export class CodeCardComponent implements OnInit {

  @Input() code?;
  @Input() showInfoUser = false;

  game?;

  constructor(
    private popoverCtrl: PopoverController,
    private apiSvc: ApiService,
    public translateSvc: TranslateService,
  ) { }

  ngOnInit() {
    // console.log(this.code);
    this.getGame();
  }

  isRedeemed() {
    if (this.code.exchanged_code === '1') {
      return true;
    }
    return false;
  }

  havePrize() {
    if (this.code.prize === 'si') {
      return true;
    }
    return false;
  }

  async openPopover() {
    const popover = await this.popoverCtrl.create({
      component: InfoCodeComponent,
      componentProps: {
        code: this.code,
        showInfoUser: this.showInfoUser
      },
      backdropDismiss: true,
      showBackdrop: true,
      cssClass: 'popover'
    });
    await popover.present();
  }

  async getGame() {
    const games = await this.translateSvc.get('GAMES.games').toPromise()
      .then((value) => value);

    if (games) {
      games.forEach(game => {
        if (game.id === this.code.game) {
          this.game = game.name;
        }
      });
    }
  }
}
