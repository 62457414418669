import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationModeService {

  private validationMode = false;

  constructor() { }

  setInitialMode() {
    const validationMode = sessionStorage.getItem('validationMode');
    if (validationMode === null || validationMode === undefined) {
      sessionStorage.setItem('validationMode', 'false');
      this.validationMode = false;
    } else {
      this.validationMode = JSON.parse(validationMode);
    }
  }

  setMode(value: boolean) {
    sessionStorage.setItem('validationMode', JSON.stringify(value));
    this.validationMode = value;
  }

  getMode() {
    return this.validationMode;
  }
}
