/* eslint-disable prefer-arrow/prefer-arrow-functions */
//Angular
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';

// Locales
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeGl from '@angular/common/locales/gl';
import localeFr from '@angular/common/locales/fr';
import localeEu from '@angular/common/locales/eu';
import localeCa from '@angular/common/locales/ca';

// Ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// Traducciones
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Guards
import { LoggedInGuard } from './shared/guards/loggedIn/loggedIn.guard';
import { ValidationModeGuard } from './shared/guards/validationMode/validationMode.guard';
import { ScannerGuard } from './shared/guards/permissions/scanner/scanner.guard';

// Componentes
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PopoversModule } from './shared/pages/popovers/popovers.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeGl, 'gl');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEu, 'eu');
registerLocaleData(localeCa, 'ca');

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: ''
    }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    PopoversModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    { provide: LOCALE_ID, useValue: 'es' },
    LoggedInGuard,
    ValidationModeGuard,
    ScannerGuard
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }
