import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from 'src/app/shared/services/permissions/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class ScannerGuard implements CanActivate {

  constructor(
    private router: Router,
    private permissionsSvc: PermissionsService
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      resolve(this.permissionsSvc.getPermissions().validateCode);
      if (!this.permissionsSvc.getPermissions().validateCode) {
        this.router.navigate(['games']);
      }
    });
  }

}
