import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/modules/shared.module';
import { IonicModule } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { PopoversRoutingModule } from './popovers-routing.module';

import { QRCodeModule } from 'angularx-qrcode';

// Popovers
import { InfoCodeComponent } from './infoCode/infoCode.component';
import { ScannedCodeComponent } from './scannedCode/scannedCode.component';

@NgModule({
  declarations: [
    InfoCodeComponent,
    ScannedCodeComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    PopoversRoutingModule,
    ReactiveFormsModule,
    QRCodeModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class PopoversModule {}
