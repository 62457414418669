import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { ApiService } from './shared/services/api/api.service';
import { LanguageService } from './shared/services/language/language.service';
import { PermissionsService } from './shared/services/permissions/permissions.service';
import { ValidationModeService } from './shared/services/validationMode/validationMode.service';

@Component({
  selector: 'app-root',
  template: '<ion-app><ion-router-outlet></ion-router-outlet></ion-app>'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private languageSvc: LanguageService,
    private apiSvc: ApiService,
    private route: ActivatedRoute,
    private permissionsSvc: PermissionsService,
    private validationModeSvc: ValidationModeService,
    private elementRef: ElementRef
  ) {
    this.platform.ready().then(() => {
      this.languageSvc.setInitialLanguage();
      this.apiSvc.setInitialURL();
      this.validationModeSvc.setInitialMode();
      this.getParams();
    });
  }

  getParams() {
    this.route.queryParams.subscribe(params => {
      // console.log(params);
      if (params.eventId !== undefined && params.jwt !== undefined) {
        this.apiSvc.params = params;
        this.apiSvc.setEventId(params.eventId);
        this.setColors(params);
        this.apiSvc.setJWT(params.jwt);
        this.permissionsSvc.setPermissions(params.jwt);
        localStorage.setItem('SELECTED_LANGUAGE', params.lng);
      }  else {
        if (localStorage.getItem('colors') !== null) {
          this.setColors(JSON.parse(localStorage.getItem('colors')));
        }
        if (localStorage.getItem('selecteduserJWT') !== null) {
          this.permissionsSvc.setPermissions(localStorage.getItem('selecteduserJWT'));
        }
      }
      // if (Object.keys(params).length !== 0) {
      //   this.apiSvc.showHeader = false;
      // }
    });
  }

  setColors(params) {
    this.elementRef.nativeElement.style.setProperty('--primary', '#' + params.primary);
    this.elementRef.nativeElement.style.setProperty('--secondary', '#' + params.secondary);
    this.elementRef.nativeElement.style.setProperty('--emphasis', '#' + params.emphasis);
    this.elementRef.nativeElement.style.setProperty('--interactive', '#' + params.interactive);

    const colors = {
      primary: params.primary,
      secondary: params.secondary,
      emphasis: params.emphasis,
      interactive: params.interactive
    };

    localStorage.setItem('colors', JSON.stringify(colors));
  }
}
