import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, IonNav, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { RascaGameComponent } from '../rascaGame/rascaGame.component';

@Component({
  selector: 'app-rasca-intro',
  templateUrl: './rascaIntro.component.html',
  styleUrls: ['./rascaIntro.component.scss'],
})
export class RascaIntroComponent implements OnInit {

  public form: FormGroup = this.fb.group({
    code: new FormControl('', [Validators.required, Validators.maxLength(8), Validators.pattern('[a-zA-Z0-9]*')])
  });

  game: any = {};

  showTooltip = false;

  gamePage = RascaGameComponent;

  constructor(
    public translateSvc: TranslateService,
    public navParams: NavParams,
    public nav: IonNav,
    public alertController: AlertController,
    public translateService: TranslateService,
    private fb: FormBuilder,
    private apiSvc: ApiService
  ) {
    this.game = this.navParams.data;
  }

  ngOnInit() { }

  startGame() {
    if (this.form.valid) {
      this.apiSvc.checkCode(this.form.value.code).then((res) => {
        if (!res.error) {
          this.showTooltip = false;
          let prize = false;
          if (res.code.prize === 'si') {
            prize = true;
          }
          this.nav.push(this.gamePage, {game: this.game, prize});
        }
      }).catch((err) => {
        switch (err.error.errorType) {
          case 'code not found':
            this.presentAlert('ERRORS.title', 'ERRORS.codeNotFound');
            break;
          case 'code is used':
            this.presentAlert('ERRORS.title', 'ERRORS.codeUsed');
            break;
        }
      });
    } else {
      this.showTooltip = true;
    }
  }

  async presentAlert(title, message) {
    const titleAlert = await this.translateService.get(title).toPromise()
      .then((value) => value);
    const messageAlert = await this.translateService.get(message).toPromise()
      .then((value) => value);
    if (titleAlert && messageAlert) {
      const alert = await this.alertController.create({
        header: titleAlert,
        message: messageAlert,
        buttons: ['Ok']
      });

      await alert.present();
    }
  }
}
