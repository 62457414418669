import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InfoCodeComponent } from './infoCode/infoCode.component';
import { ScannedCodeComponent } from './scannedCode/scannedCode.component';

const routes: Routes = [
  {
    path: '',
    component: InfoCodeComponent
  },
  {
    path: '',
    component: ScannedCodeComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
})
export class PopoversRoutingModule { }
