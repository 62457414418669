import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/services/api/api.service';

@Component({
  selector: 'app-info-code',
  templateUrl: './infoCode.component.html',
  styleUrls: ['./infoCode.component.scss'],
})
export class InfoCodeComponent implements OnInit {

  @Input() code?;
  @Input() showInfoUser?;
  infoUser?;

  game?;

  showScrimQR = false;

  constructor(
    private popoverCtrl: PopoverController,
    public translateSvc: TranslateService,
    private apiSvc: ApiService,
  ) { }

  ngOnInit() {
    if (this.isRedeemed() || !this.havePrize()) {
      this.showScrimQR = true;
    }
    this.getGame();
    if (this.showInfoUser) {
      this.getInfoUser();
    }
  }

  isRedeemed() {
    if (this.code.exchanged_code === '1') {
      return true;
    }
    return false;
  }

  havePrize() {
    if (this.code.prize === 'si') {
      return true;
    }
    return false;
  }

  closePopover() {
    this.popoverCtrl.dismiss();
  }

  showQR() {
    this.showScrimQR = false;
  }

  getTitle() {
    if (this.havePrize()) {
      return 'GAMES.myCodes.infoPopover.prized.title';
    } else {
      return 'GAMES.myCodes.infoPopover.noPrized.title';
    }
  }

  getSubtitle() {
    if (this.havePrize()) {
      if (this.isRedeemed()) {
        return 'GAMES.myCodes.infoPopover.prized.subtitleRedeemed';
      } else {
        return 'GAMES.myCodes.infoPopover.prized.subtitleNoRedeemed';
      }
    } else {
      return 'GAMES.myCodes.infoPopover.noPrized.subtitle';
    }
  }

  async getGame() {
    const games = await this.translateSvc.get('GAMES.games').toPromise()
      .then((value) => value);

    if (games) {
      games.forEach(game => {
        if (game.id === this.code.game) {
          this.game = game.name;
        }
      });
    }
  }

  async getInfoUser() {
    const assistants = await this.apiSvc.getAssistants().then(res => res.users).catch(err => err);
    if (assistants) {
      assistants.forEach(assistant => {
        if (assistant.id === this.code.user_id) {
          this.infoUser = assistant;
        }
      });
    }
  }
}
