import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** PIPES */
import { ImageGamePipe } from '../shared/pipes/imageGame/imageGame.pipe';
import { FilterPipe } from '../shared/pipes/filter/filter.pipe';

/** COMPONENTS */
import { ButtonComponent } from '../shared/components/custom/button/button.component';
import { CheckboxComponent } from '../shared/components/custom/checkbox/checkbox.component';
import { RascaIntroComponent } from '../shared/pages/rasca/rascaIntro/rascaIntro.component';
import { RascaGameComponent } from '../shared/pages/rasca/rascaGame/rascaGame.component';
import { TooltipErrorComponent } from '../shared/components/tooltipError/tooltipError.component';
import { ContextmenuComponent } from '../shared/components/custom/contextmenu/contextmenu.component';
import { CodeCardComponent } from '../shared/components/codeCard/codeCard.component';

@NgModule({
  declarations: [
    ImageGamePipe,
    FilterPipe,
    ButtonComponent,
    CheckboxComponent,
    RascaIntroComponent,
    RascaGameComponent,
    TooltipErrorComponent,
    ContextmenuComponent,
    CodeCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    RouterModule,
    TranslateModule,
    IonicModule,
    CommonModule,
    ImageGamePipe,
    FilterPipe,
    ButtonComponent,
    CheckboxComponent,
    RascaIntroComponent,
    RascaGameComponent,
    FormsModule,
    ReactiveFormsModule,
    TooltipErrorComponent,
    CodeCardComponent
  ]
})
export class SharedModule { }
