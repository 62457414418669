import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ValidationModeService } from '../../services/validationMode/validationMode.service';

@Injectable({
  providedIn: 'root'
})
export class ValidationModeGuard implements CanActivate {

  constructor(
    private router: Router,
    private validationModeSvc: ValidationModeService
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.validationModeSvc.getMode()) {
        resolve(false);
        this.router.navigate(['scan']);
      }
      resolve(true);
    });
  }
}
