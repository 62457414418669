/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/services/api/api.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-scanned-code',
  templateUrl: './scannedCode.component.html',
  styleUrls: ['./scannedCode.component.scss'],
})
export class ScannedCodeComponent implements OnInit {

  @Input() code?;
  infoCode?;
  infoUser?;
  game;

  showInfo = false;

  nameUserScanner?;
  dateScanner?;

  error?;
  errorType = '';

  constructor(
    private popoverCtrl: PopoverController,
    private apiSvc: ApiService,
    public translateSvc: TranslateService,
  ) { }

  ngOnInit() {
    this.validateCode();
  }

  async validateCode() {
    const response = await this.apiSvc.validateCode(this.code).then(res => res).catch(err => err);
    if (response) {
      // console.log(response);
      if (response.error.error) {
        this.error = true;
        this.errorType = response.error.errorType;
      } else {
        this.error = false;
        this.errorType = '';
      }
      this.infoCode = await this.apiSvc.getCodeInfo(this.code).then(res => res.codes).catch(err => err);
      if (this.infoCode) {
        // console.log(this.infoCode);
        this.getGame();
        this.nameUserScanner = jwt_decode(localStorage.getItem('selecteduserJWT'))['name'];
        this.dateScanner = new Date();
        this.showInfo = this.havePrize();
        if (!this.havePrize()) {
          this.error = true;
          this.errorType = 'no-prize';
        }
        const assistants = await this.apiSvc.getAssistants().then(res => res.users).catch(err => err);
        if (assistants) {
          assistants.forEach(assistant => {
            if (assistant.id === this.infoCode.user_id) {
              this.infoUser = assistant;
            }
          });
        }
      }
    }
  }

  havePrize() {
    if (this.infoCode.prize === 'si') {
      return true;
    }
    return false;
  }

  closePopover(value: boolean) {
    this.popoverCtrl.dismiss({ value });
  }

  async getGame() {
    const games = await this.translateSvc.get('GAMES.games').toPromise()
      .then((value) => value);

    if (games) {
      games.forEach(game => {
        if (game.id === this.infoCode.game) {
          this.game = game.name;
        }
      });
    }
  }
}
