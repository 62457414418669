/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IonNav, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-rasca-game',
  templateUrl: './rascaGame.component.html',
  styleUrls: ['./rascaGame.component.scss'],
})
export class RascaGameComponent implements OnInit {

  game: any = {};

  isDrawing;
  lastPoint;
  canvas;
  ctx;
  image = new Image();
  brush;
  canvasWidth;
  canvasHeight;

  reveal;
  prize = false;

  constructor(
    public navParams: NavParams,
    public nav: IonNav,
    public router: Router
  ) {
    this.game = this.navParams.data.game;
    this.prize = this.navParams.data.prize;
  }

  ngOnInit() {
    this.setImage();
  }

  setImage() {
    const canvasContainer = document.getElementById('canvas-container') as HTMLDivElement;
    this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d');
    this.canvasWidth = this.canvas.width;
    this.canvasHeight = this.canvas.height;
    this.reveal = false;
    this.brush = new Image();
    this.brush.src = this.game.brush;
    this.image.src = this.game.scratch;
    this.image.onload = () => {
      this.ctx.drawImage(this.image, 0, 0, 300, 300);
      if (this.prize) {
        canvasContainer.style.background = 'url(' + this.game.prize + ') no-repeat center center / cover';
      } else {
        canvasContainer.style.background = 'url(' + this.game.noPrize + ') no-repeat center center / cover';
      }
    };
    this.canvas.addEventListener('mousedown', (e) => {
      this.isDrawing = true;
      this.lastPoint = this.getMouse(e, this.canvas);
    });
    this.canvas.addEventListener('touchstart', (e) => {
      this.isDrawing = true;
      this.lastPoint = this.getMouse(e, this.canvas);
    });
    this.canvas.addEventListener('mousemove', (e) => {
      if (!this.isDrawing) { return; }

      e.preventDefault();

      const currentPoint = this.getMouse(e, this.canvas);
      const dist = this.distanceBetween(this.lastPoint, currentPoint);
      const angle = this.angleBetween(this.lastPoint, currentPoint);
      let x;
      let y;

      for (let i = 0; i < dist; i++) {
        x = this.lastPoint.x + (Math.sin(angle) * i) - 25;
        y = this.lastPoint.y + (Math.cos(angle) * i) - 25;
        this.ctx.globalCompositeOperation = 'destination-out';
        this.ctx.drawImage(this.brush, x, y);
      }

      this.lastPoint = currentPoint;
      this.handlePercentage(this.getFilledInPixels(32));
    });
    this.canvas.addEventListener('touchmove', (e) => {
      if (!this.isDrawing) { return; }

      e.preventDefault();

      const currentPoint = this.getMouse(e, this.canvas);
      const dist = this.distanceBetween(this.lastPoint, currentPoint);
      const angle = this.angleBetween(this.lastPoint, currentPoint);
      let x;
      let y;

      for (let i = 0; i < dist; i++) {
        x = this.lastPoint.x + (Math.sin(angle) * i) - 25;
        y = this.lastPoint.y + (Math.cos(angle) * i) - 25;
        this.ctx.globalCompositeOperation = 'destination-out';
        this.ctx.drawImage(this.brush, x, y);
      }

      this.lastPoint = currentPoint;
      this.handlePercentage(this.getFilledInPixels(32));
    });
    this.canvas.addEventListener('mouseup', (e) => {
      this.isDrawing = false;
    });
    this.canvas.addEventListener('touchend', (e) => {
      this.isDrawing = false;
    });
  }

  distanceBetween(point1, point2) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
  }

  angleBetween(point1, point2) {
    return Math.atan2(point2.x - point1.x, point2.y - point1.y);
  }

  getFilledInPixels(stride) {
    if (!stride || stride < 1) { stride = 1; }

    const pixels = this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight);
    const pdata = pixels.data;
    const l = pdata.length;
    const total = (l / stride);
    let count = 0;

    // Iterate over all pixels
    for (let i = count = 0; i < l; i += stride) {
      if (parseInt(pdata[i], 10) === 0) {
        count++;
      }
    }

    return Math.round((count / total) * 100);
  }

  getMouse(e, canvas) {
    let offsetX = 0;
    let offsetY = 0;

    if (canvas.offsetParent !== undefined) {
      do {
        offsetX += canvas.offsetLeft;
        offsetY += canvas.offsetTop;
      } while ((canvas = canvas.offsetParent));
    }

    const mx = (e.pageX || e.touches[0].clientX) - offsetX;
    const my = (e.pageY || e.touches[0].clientY) - offsetY;

    return { x: mx, y: my };
  }

  handlePercentage(filledInPixels) {
    filledInPixels = filledInPixels || 0;
    // console.log(filledInPixels + '%');
    if (filledInPixels > 80) {
      try {
        this.canvas.parentNode.removeChild(this.canvas);
      } catch (e) {}
      this.reveal = true;
    }
  }

  goBack(e) {
    this.nav.pop();
  }

  goToGames(e) {
    this.nav.pop();
    this.router.navigate(['/games']);
  }
}
