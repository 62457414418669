/* eslint-disable no-fallthrough */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private sub: any;

  private permissions = {
    validateCode: false,
    showHistory: false,
    activateValidationMode: false,
    showAllHistory: false
  };

  constructor(private apiSvc: ApiService) { }

  setSub(jwt) {
    this.sub = jwt_decode(jwt)['sub'];
  }

  getSub() {
    return this.sub;
  }

  setPermissions(jwt) {
    this.setSub(jwt);
    switch (this.sub) {
      case 'EMPLOYEE':
      case 'BASIC':
      case 'MEDIUM':
      case 'PREMIUM':
        this.permissions = {
          validateCode: false,
          showHistory: false,
          activateValidationMode: false,
          showAllHistory: false
        };
        break;
      case 'ADMIN':
        this.permissions = {
          validateCode: true,
          showHistory: true,
          activateValidationMode: true,
          showAllHistory: false
        };
        break;
      case 'ORGANIZER':
        this.permissions = {
          validateCode: true,
          showHistory: true,
          activateValidationMode: true,
          showAllHistory: true
        };
        break;
    };
  }

  getPermissions() {
    return this.permissions;
  }
}
